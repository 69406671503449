<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfProcedure"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRules"
        label="Dauer (HH:MM)"
      ></v-text-field>
      <v-text-field
        :readonly="disabled"
        v-model="doctorId"
        label="Kürzel Arzt"
      ></v-text-field>

      <v-select
        :readonly="disabled"
        v-model="selected.values"
        :items="selects"
        label="Getroffene Entscheidungen"
        multiple
        @update:model-value="checkSelections()"
      ></v-select>

      <v-textarea
        v-show="if_1 || (further.documentation.content.measure && disabled)"
        :readonly="disabled"
        v-model="further.documentation.content.measure"
        label="veranlasste Maßnahmen (stichpunktartige Erfassung)"
      ></v-textarea>

      <v-textarea
        v-show="
          if_2 || (further.documentation.content.clinic.reason && disabled)
        "
        :readonly="disabled"
        v-model="further.documentation.content.clinic.reason"
        label="Grund der Einbestellung"
      ></v-textarea>

      <v-textarea
        v-show="
          if_2 || (further.documentation.content.clinic.measure && disabled)
        "
        :readonly="disabled"
        v-model="further.documentation.content.clinic.measure"
        label="Datum der Einbestellung und veranlasste Maßnahmen"
      ></v-textarea>

      <v-textarea
        v-show="if_3 || (further.documentation.content.briefing && disabled)"
        :readonly="disabled"
        v-model="further.documentation.content.briefing"
        label="Grund der stationären Einweisung"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content.further"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "PatientCouncil",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Videokonsultation Facharzt - Patient",
      type: "video_consultation",
      billableProcedures: ["A98"],
      doctorId: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "doctorId"
          ).value
        : "",
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRules: [
        (value) => {
          if (value) return true;

          return "Sie müssen eine Dauer angeben";
        },
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: {
              status: false,
              content: {
                further: "",
                measure: "",
                clinic: { reason: "", measure: "" },
                briefing: "",
              },
            },
          },
      selected: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "selected"
          ).value
        : { values: [] },
      selects: [
        "Einleitung von Maßnahme/n",
        "Entscheidung zu Einbestellung in Klinik/Praxis",
        "stationäre Einweisung",
      ],
      notEmptyRule: [
        (value) => {
          if (value) {
            return true;
          }

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      disabled: this.disabled_field,
      if_1: false,
      if_2: false,
      if_3: false,
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfProcedure,
        content: [
          { property: "further", value: this.further },
          { property: "selected", value: this.selected },
          { property: "duration", value: this.duration },
          { property: "doctorId", value: this.doctorId },
        ],
        fileUploads: [], // No file uploads in this component
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    checkSelections: function () {
      if (this.selected.values.includes("Einleitung von Maßnahme/n")) {
        this.if_1 = true;
      } else {
        this.if_1 = false;
      }

      if (
        this.selected.values.includes(
          "Entscheidung zu Einbestellung in Klinik/Praxis"
        )
      ) {
        this.if_2 = true;
      } else {
        this.if_2 = false;
      }

      if (this.selected.values.includes("stationäre Einweisung")) {
        this.if_3 = true;
      } else {
        this.if_3 = false;
      }
    },

    clearInput: function () {
      this.dateOfProcedure = "";
      this.dateMsg = true;
    },
  },

  mounted() {
    console.log(this.dischargeLetterPDF);
  },
};
</script>
