<template>
  <v-sheet v-if="!loading" border="md" rounded="lg">
    <v-card-text
      class="overflow-y-auto"
      style="max-height: 600px"
      @change="scrollToBottom"
    >
      <div
        v-for="comment in commentsPrint()"
        :key="comment._id"
        ref="chat_window"
        :class="[
          'd-flex flex-row align-center my-2',
          comment.user_id == user_name ? 'justify-end' : null,
        ]"
      >
        <v-avatar
          v-show="comment.user_id != user_name"
          class="pa-4 mb-2 mr-1 avatar-border"
          :color="comment.user_color"
        >
          <v-tooltip activator="parent" location="start">{{
            comment.tool_tip
          }}</v-tooltip>
          <span class="white--text">{{ comment.user_short_cut }}</span>
        </v-avatar>

        <v-chip
          :color="comment.user_id == user_name ? 'primary' : ''"
          dark
          label
          style="height: auto"
          class="pa-4 mb-2"
        >
          <v-row class="ma-0" style="white-space: break-spaces">{{
            comment.comment
          }}</v-row>
          <v-row class="mr-1 d-inline">
            <sub style="font-size: 0.5rem; height: auto">
              {{ comment.formatted_time }}</sub
            ></v-row
          >
        </v-chip>

        <v-avatar
          v-show="comment.user_id == user_name"
          class="pa-4 mb-2 ml-1 avatar-border"
          color="indigo"
        >
          <span class="white--text">{{ comment.user_short_cut }}</span>
        </v-avatar>
      </div>
    </v-card-text>
    <v-card-text class="flex-shrink-1">
      <p v-if="comments.length === 0">Keine Nachrichten vorhanden</p>
      <v-textarea
        rows="1"
        v-model="message"
        label="Kommentar schreiben"
        type="text"
        no-details
        outlined
        append-icon="mdi-send"
        @keydown.enter.exact.prevent="message += '\n'"
        @keydown.enter.shift.exact.prevent="sendComment"
        @click:append="sendComment"
        hide-details
      />
      <p class="text-right">Nachricht senden: Shift+Enter</p>

      <v-row class="mt-2">
        <v-col cols="3" class="pa-4 mb-2"
          ><v-avatar size="10" class="avatar-border" color="red"></v-avatar> -
          Arzt</v-col
        >
        <v-col cols="3" class="pa-4 mb-2"
          ><v-avatar size="10" class="avatar-border" color="green"></v-avatar> -
          Parkinson-Nurse</v-col
        >
        <v-col cols="3" class="pa-4 mb-2"
          ><v-avatar size="10" class="avatar-border" color="purple"></v-avatar>
          - Pflegedienst</v-col
        >
      </v-row>
      <v-row class="mt-2">
        <v-col cols="3" class="pa-4 mb-2"
          ><v-avatar size="10" class="avatar-border" color="white"></v-avatar> -
          Koordinator</v-col
        >
        <v-col cols="3" class="pa-4 mb-2"
          ><v-avatar size="10" class="avatar-border" color="blue"></v-avatar> -
          eigene Nachrichten</v-col
        >
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<style>
.avatar-border {
  border: 0.5px solid black;
}
</style>

<script>
import { jwtDecode } from "jwt-decode";

export default {
  name: "TherapyDialogue",

  data: () => ({
    comments: [],
    dialog: false,
    message: "",
    loading: true,
  }),

  methods: {
    inputHandler(e) {
      // not used atm
      if (e.keyCode === 13 && !e.shiftKey) {
        console.log("nextline");
      } else if (e.keyCode === 13) {
        this.sendComment();
      }
    },
    fetchComments: function () {
      this.loading = true;
      let patient_id = this.$route.params.id.split("&", 1);

      this.$axios
        .get("/get_comments/" + patient_id)
        .then(
          (response) => {
            this.comments = response.data;
            if (this.comments != {}) {
              this.comments = Object.values(this.comments)
                .sort(function (a, b) {
                  return new Date(b.date) - new Date(a.date);
                })
                .reverse();
            }
          },
          () => {}
        )
        .then(
          () => {
            this.loading = false;
            this.scrollToBottom();
          },
          () => {}
        );
    },

    sendComment: function () {
      if (this.message) {
        const token = this.keycloak.token;
        const decoded_token = jwtDecode(token);
        let user_name = decoded_token.preferred_username;

        let patient_id = this.$route.params.id.split("&", 1)[0];
        console.log(user_name);
        console.log(patient_id);

        let json_payload = {
          user_id: user_name,
          patient_id: patient_id,
          date: new Date().toISOString(),
          comment: this.message,
        };

        this.$axios
          .post("/comment/", json_payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(
            (response) => {
              if ("error" in response.data) {
                console.log(response.data);
                return;
              } else {
                console.log("Comment created");
                this.message = "";
                this.fetchComments();
              }
            },
            () => {
              console.log("Comment not created");
            }
          );
      }
    },

    commentsPrint: function () {
      let user_data = {};
      this.$axios
        .get("/get_users/1")
        .then(
          (response) => {
            user_data = response.data;
          },
          () => {}
        )
        .then(() => {
          this.comments.forEach((element) => {
            let date = new Date(element.date);
            let user = Object.values(user_data).find(
              (item) => item.user_id === element.user_id
            );
            element.user_name_display = user.first_name + " " + user.last_name;
            element.formatted_time = date.toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            });
            element.user_short_cut =
              user.first_name.slice(0, 1) + user.last_name.slice(0, 1);
            if (user.roles.includes("doctor")) {
              element.user_color = "red";
            } else if (user.roles.includes("nurse")) {
              element.user_color = "green";
            } else if (user.roles.includes("care_giver")) {
              console.log("test");
              element.user_color = "purple";
            }
            element.tool_tip =
              element.user_name_display + ", Rollen: " + user.roles.toString();
          });
        });

      this.loading = false;
      return this.comments;
    },

    switchedTo: function () {
      this.fetchComments();
    },

    scrollToBottom: function () {
      this.$nextTick(() => {
        let chat_elements = this.$refs.chat_window;

        if (chat_elements && chat_elements.length > 0) {
          // Get the last chat element
          let lastChatElement = chat_elements[chat_elements.length - 1];

          // Find the parent element that should be scrolled
          let parentElement = lastChatElement.parentElement;

          // Calculate the total height of all chat elements up to the last one
          let totalHeight = 0;
          for (let i = 0; i < chat_elements.length; i++) {
            totalHeight += chat_elements[i].offsetHeight;
          }

          // Set the scrollTop of the parent element to the total height
          parentElement.scrollTop = totalHeight;
        }
      });
    },
  },

  computed: {
    user_name: function () {
      return jwtDecode(this.keycloak.token).preferred_username;
    },
  },

  mounted() {
    this.fetchComments();
  },
};
</script>
