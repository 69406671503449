<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfProcedure"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer (HH:MM)"
      ></v-text-field>
      <v-text-field
        :readonly="disabled"
        v-model="nurseId"
        label="Kürzel Study Nurse"
      ></v-text-field>
      <v-textarea
        :readonly="disabled"
        v-model="content"
        label="Gesprächsinhalte"
      ></v-textarea>
      <v-file-input
        v-show="!disabled"
        v-model="docFile"
        :rules="fileRules"
        accept=".pdf"
        label="PDF hochladen"
      ></v-file-input>

      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">hochgeladenes Dokument</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "NursePatientCall",

  components: {
    VuePdfEmbed,
  },

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Telefonat Parkinson Nurse - Patient",
      type: "nurse_patient_call",
      billableProcedures: ["A06b"],
      filePDF: this.document_data
        ? this.document_data.content.find((item) => item.property === "docFile")
            .value
        : "Not provided",
      fileBlob: undefined,
      docFile: undefined,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      disabled: this.disabled_field,
      nurseId: this.document_data
        ? this.document_data.content.find((item) => item.property === "nurseId")
            .value
        : "",
      content: this.document_data
        ? this.document_data.content.find((item) => item.property === "content")
            .value
        : "",
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfProcedure,
        content: [
          { property: "duration", value: this.duration },
          { property: "nurseId", value: this.nurseId },
          { property: "content", value: this.content },
        ],
        fileUploads: [
          {
            file: this.docFile,
            property: "docFile",
            prefix: "DocumentNPC",
          },
        ],
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },
  },

  watch: {
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },

    clearInput: function () {
      this.dateOfProcedure = "";
      this.dateMsg = true;
    },
  },

  mounted() {
    console.log(this.filePDF);
  },
};
</script>
