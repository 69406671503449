<template>
  <v-container>
    <v-row>
      <!-- <v-col>
              <v-btn color="btn btn-primary" v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
                  @click="createQFormPatient" prepend-icon="mdi-plus">
                  Patient in QForm anlegen und Fragebögen zuweisen
              </v-btn>
          </v-col> -->
      <v-col>
        <v-btn
          color="btn btn-primary"
          v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
          @click="
            dialog = true;
            getQuestionnaires();
          "
          prepend-icon="mdi-plus"
        >
          Patienten Fragebogen zuweisen
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="btn btn-primary"
          v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
          @click="getQuestionnaireScores"
          prepend-icon="mdi-plus"
        >
          Abruf der aktuellen Daten aus QForm
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="btn btn-primary"
          v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
          href="https://dizqform.med.uni-giessen.de:50001/login"
          target="_blank"
          rel="noopener noreferrer"
          prepend-icon="mdi-plus"
        >
          QForm öffnen
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Patienten Fragebogen zuweisen</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="assignQuestionnaire">
            <v-row>
              <v-select
                label="Zuzuordnenden Fragebogen auswählen"
                :items="Object.values(questionnaires)"
                v-model="selectedQuestionnaire"
                v-validate="'required'"
              >
              </v-select>
            </v-row>
            <v-row>
              <v-col>
                <v-btn v-show="!disabled" type="submit" block class="mt-2"
                  >Fragebogen zuordnen</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="dialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="questionnaire_refresh" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Fragebogen aktualisieren</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updateQuestionnaire">
            <div
              v-for="(q_s, q_idx) in questionnaire_to_refresh_data[1].item"
              :key="q_idx"
            >
              <div v-if="q_s.text !== 'score'">
                <!-- q_score.item.item + headline -->
                <h2>{{ q_s.text }}</h2>
                <div v-for="(q_s_2, q_idx_2) in q_s.item" :key="q_idx_2">
                  <div v-if="checkIfObjectIncludes(q_s_2, 'item')">
                    <!-- q_score.item.item.item + headline-->
                    <h3>{{ q_s_2.text }}</h3>
                    <div v-for="(q_s_3, q_idx_3) in q_s_2.item" :key="q_idx_3">
                      <div v-if="checkIfObjectIncludes(q_s_3, 'item')">
                        <!-- q_score.item.item.item.item + headline-->
                        <strong>{{ q_s_3.text }}</strong>
                        <div
                          v-for="(q_s_4, q_idx_4) in q_s_3.item"
                          :key="q_idx_4"
                        >
                          <div v-if="q_s_4.answer.length !== 0">
                            <div
                              v-if="
                                checkIfObjectIncludes(
                                  q_s_4.answer[0],
                                  'valueBoolean'
                                )
                              "
                            >
                              <v-radio-group
                                v-model="q_s_4.answer[0].valueBoolean"
                                :label="q_s_4.text"
                                inline
                              >
                                <v-radio label="Ja" value="true"></v-radio>
                                <v-radio label="Nein" value="false"></v-radio>
                              </v-radio-group>
                            </div>
                            <div
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_4.answer[0],
                                  'valueDecimal'
                                )
                              "
                            >
                              <v-text-field
                                v-model="q_s_4.answer[0].valueDecimal"
                                :label="q_s_4.text"
                                type="number"
                              ></v-text-field>
                            </div>
                            <div
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_4.answer[0],
                                  'valueCoding'
                                )
                              "
                            >
                              <v-text-field
                                v-model="q_s_4.answer[0].valueCoding.display"
                                :label="q_s_4.text"
                              ></v-text-field>
                            </div>
                            <div
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_4.answer[0],
                                  'valueString'
                                )
                              "
                            >
                              <v-text-field
                                v-model="q_s_4.answer[0].valueString"
                                :label="q_s_4.text"
                              ></v-text-field>
                            </div>
                            <div
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_4.answer[0],
                                  'valueTime'
                                )
                              "
                            >
                              <v-text-field
                                v-model="q_s_4.answer[0].valueTime"
                                :label="q_s_4.text"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="q_s_3.answer.length !== 0">
                          <div
                            v-if="
                              checkIfObjectIncludes(
                                q_s_3.answer[0],
                                'valueBoolean'
                              )
                            "
                          >
                            <v-radio-group
                              v-model="q_s_3.answer[0].valueBoolean"
                              :label="q_s_3.text"
                              inline
                            >
                              <v-radio label="Ja" value="true"></v-radio>
                              <v-radio label="Nein" value="false"></v-radio>
                            </v-radio-group>
                          </div>
                          <div
                            v-else-if="
                              checkIfObjectIncludes(
                                q_s_3.answer[0],
                                'valueDecimal'
                              )
                            "
                          >
                            <v-text-field
                              v-model="q_s_3.answer[0].valueDecimal"
                              :label="q_s_3.text"
                              type="number"
                            ></v-text-field>
                          </div>
                          <div
                            v-else-if="
                              checkIfObjectIncludes(
                                q_s_3.answer[0],
                                'valueCoding'
                              )
                            "
                          >
                            <v-text-field
                              v-model="q_s_3.answer[0].valueCoding.display"
                              :label="q_s_3.text"
                            ></v-text-field>
                          </div>
                          <div
                            v-else-if="
                              checkIfObjectIncludes(
                                q_s_3.answer[0],
                                'valueString'
                              )
                            "
                          >
                            <v-text-field
                              v-model="q_s_3.answer[0].valueString"
                              :label="q_s_3.text"
                            ></v-text-field>
                          </div>
                          <div
                            v-else-if="
                              checkIfObjectIncludes(
                                q_s_3.answer[0],
                                'valueTime'
                              )
                            "
                          >
                            <v-text-field
                              v-model="q_s_3.answer[0].valueTime"
                              :label="q_s_3.text"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="q_s_2.answer.length !== 0">
                      <div
                        v-if="
                          checkIfObjectIncludes(q_s_2.answer[0], 'valueBoolean')
                        "
                      >
                        <v-radio-group
                          v-model="q_s_2.answer[0].valueBoolean"
                          :label="q_s_2.text"
                          inline
                        >
                          <v-radio label="Ja" value="true"></v-radio>
                          <v-radio label="Nein" value="false"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        v-else-if="
                          checkIfObjectIncludes(q_s_2.answer[0], 'valueDecimal')
                        "
                      >
                        <v-text-field
                          v-model="q_s_2.answer[0].valueDecimal"
                          :label="q_s_2.text"
                          type="number"
                        ></v-text-field>
                      </div>
                      <div
                        v-else-if="
                          checkIfObjectIncludes(q_s_2.answer[0], 'valueCoding')
                        "
                      >
                        <v-text-field
                          v-model="q_s_2.answer[0].valueCoding.display"
                          :label="q_s_2.text"
                        ></v-text-field>
                      </div>
                      <div
                        v-else-if="
                          checkIfObjectIncludes(q_s_2.answer[0], 'valueString')
                        "
                      >
                        <v-text-field
                          v-model="q_s_2.answer[0].valueString"
                          :label="q_s_2.text"
                        ></v-text-field>
                      </div>
                      <div
                        v-else-if="
                          checkIfObjectIncludes(q_s_2.answer[0], 'valueTime')
                        "
                      >
                        <v-text-field
                          v-model="q_s_2.answer[0].valueTime"
                          :label="q_s_2.text"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div>
              <v-row>
                <v-col>
                  <v-btn v-show="!disabled" type="submit" block class="mt-2"
                    >Fragebogen aktualisieren</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="questionnaire_refresh = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      v-for="(questionnaire_score_type, key) in questionnaire_scores"
      :key="key"
    >
      <v-row>
        <v-col>
          <h5 class="mt-2">{{ key }}</h5>
        </v-col>
      </v-row>
      <v-expansion-panels variant="accordion">
        <v-col>
          <v-row
            class="mb-0"
            v-for="(questionnaire_score, index) in questionnaire_score_type"
            :key="index"
          >
            <v-expansion-panel>
              <v-expansion-panel-title>
                <div v-if="key === 'NMSS'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  360
                </div>
                <div v-else-if="key === 'PDQ39'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  100
                </div>
                <div v-else-if="key === 'MoCA'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  30
                </div>
                <div v-else-if="key === 'MDS-UPDRS'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  260
                </div>
                <div v-else-if="key === 'Barthel-Index'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  100
                </div>
                <div v-else-if="key === 'BDI-2'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  63
                </div>
                <div
                  v-else-if="
                    key === 'BDI-2 - Angehörige' ||
                    key === 'BDI-2 - Patient:innen'
                  "
                >
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  63
                </div>
                <div v-else-if="key === 'WHO-5'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  25
                </div>
                <div v-else-if="key === 'WHO-5 - Angehörige'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  25
                </div>
                <div
                  v-else-if="key === 'WHO-5 - Patient:innen (Kontrollgruppe)'"
                >
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - Score
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }}
                </div>
                <div v-else-if="key === 'ZBI - Angehörige'">
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                  - erreichter Score/Maximalscore:
                  {{ questionnaire_score[1].item[0].answer[0].valueDecimal }} /
                  88
                </div>
                <div v-else-if="key === 'Hoehn-Yahr'">
                  {{ key }} vom
                  {{
                    helpers.formatDateStringToGermanDate(
                      questionnaire_score[1].authored
                    )
                  }}
                  - Score:
                  {{
                    extractHoehnYahrScore(
                      questionnaire_score[1].item[0].answer[0].valueCoding.display.slice(
                        0,
                        3
                      )
                    )
                  }}
                </div>
                <div v-else>
                  {{ key }} vom
                  <span
                    v-if="
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === 'Ausgefüllt am.../ Poststempeldatum'
                      )
                    "
                  >
                    {{
                      questionnaire_score[1]?.item.find(
                        (entry) =>
                          entry.text === "Ausgefüllt am.../ Poststempeldatum"
                      ).answer[0].valueString
                    }}
                  </span>
                  <span v-else>
                    {{
                      helpers.formatDateStringToGermanDate(
                        questionnaire_score[1].authored
                      )
                    }}
                  </span>
                </div>
                <v-col class="text-right">
                  <v-btn
                    color="btn btn-primary"
                    class="text-right"
                    v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
                    @click="
                      questionnaire_refresh = true;
                      questionnaire_to_refresh_data = questionnaire_score;
                      questionnaire_to_refresh_index = index;
                    "
                    prepend-icon="mdi-plus"
                  >
                    Fragebogen aktualisieren
                  </v-btn>
                </v-col>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-container>
                  <div
                    v-for="(q_s, q_idx) in questionnaire_score[1].item"
                    :key="q_idx"
                  >
                    <div
                      v-if="
                        q_s.text === 'Ausgefüllt am.../ Poststempeldatum' ||
                        q_s.text === 'Ausgefüllt am...'
                      "
                    >
                      <div
                        v-if="
                          checkIfObjectIncludes(q_s.answer[0], 'valueString')
                        "
                      >
                        <p>{{ q_s.text }}: {{ q_s.answer[0].valueString }}</p>
                      </div>
                    </div>
                    <div
                      v-if="
                        q_s.text !== 'score' &&
                        q_s.text !== 'Ausgefüllt am.../ Poststempeldatum' &&
                        q_s.text !== 'Ausgefüllt am...'
                      "
                    >
                      <!-- print Hoehn-Yahr answer-->
                      <br />
                      <h2 class="text-align:left" v-if="key === 'Hoehn-Yahr'">
                        {{ q_s.text }}: {{ q_s.answer[0].valueCoding.display }}
                      </h2>
                      <!-- q_score.item.item + headline -->
                      <h2 class="text-align:left" v-else>{{ q_s.text }}</h2>
                      <br />

                      <div v-for="(q_s_2, q_idx_2) in q_s.item" :key="q_idx_2">
                        <div v-if="checkIfObjectIncludes(q_s_2, 'item')">
                          <v-row class="mb-2">
                            <v-col class="text-align:left" cols="6">
                              <h3>{{ q_s_2.text }}</h3>
                            </v-col>
                          </v-row>
                          <!-- q_score.item.item.item + headline-->
                          <div
                            v-for="(q_s_3, q_idx_3) in q_s_2.item"
                            :key="q_idx_3"
                          >
                            <div v-if="checkIfObjectIncludes(q_s_3, 'item')">
                              <v-row class="mb-2">
                                <v-col class="text-align:left" cols="6">
                                  <strong>{{ q_s_3.text }}</strong>
                                </v-col>
                              </v-row>
                              <!-- q_score.item.item.item.item + headline-->
                              <div
                                v-for="(q_s_4, q_idx_4) in q_s_3.item"
                                :key="q_idx_4"
                              >
                                <div v-if="q_s_4.answer.length !== 0">
                                  <v-row
                                    class="mb-2"
                                    v-if="
                                      checkIfObjectIncludes(
                                        q_s_4.answer[0],
                                        'valueBoolean'
                                      )
                                    "
                                  >
                                    <v-col class="text-align:left">
                                      {{ q_s_4.text }}:
                                    </v-col>
                                    <v-col class="text-align:left">
                                      <p
                                        v-if="
                                          q_s_4.answer[0].valueBoolean ===
                                          'true'
                                        "
                                      >
                                        Ja
                                      </p>
                                      <p v-else>Nein</p>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="mb-2"
                                    v-else-if="
                                      checkIfObjectIncludes(
                                        q_s_4.answer[0],
                                        'valueDecimal'
                                      )
                                    "
                                  >
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.text }}:
                                    </v-col>
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.answer[0].valueDecimal }}
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="mb-2"
                                    v-else-if="
                                      checkIfObjectIncludes(
                                        q_s_4.answer[0],
                                        'valueCoding'
                                      )
                                    "
                                  >
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.text }}:
                                    </v-col>
                                    <v-col class="text-align:left" cols="6">
                                      <div
                                        v-if="
                                          getAnswerColor(
                                            q_s_4.answer[0].valueCoding.display
                                          ) === 'red'
                                        "
                                      >
                                        <p style="background-color: red">
                                          {{
                                            q_s_4.answer[0].valueCoding.display
                                          }}
                                        </p>
                                      </div>
                                      <div
                                        v-else-if="
                                          getAnswerColor(
                                            q_s_4.answer[0].valueCoding.display
                                          ) === 'yellow'
                                        "
                                      >
                                        <p style="background-color: #ffd700">
                                          {{
                                            q_s_4.answer[0].valueCoding.display
                                          }}
                                        </p>
                                      </div>
                                      <div v-else>
                                        <p style="background-color: white">
                                          {{
                                            q_s_4.answer[0].valueCoding.display
                                          }}
                                        </p>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="mb-2"
                                    v-else-if="
                                      checkIfObjectIncludes(
                                        q_s_4.answer[0],
                                        'valueTime'
                                      )
                                    "
                                  >
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.text }}:
                                    </v-col>
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.answer[0].valueTime }}
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="mb-2"
                                    v-else-if="
                                      checkIfObjectIncludes(
                                        q_s_4.answer[0],
                                        'valueString'
                                      )
                                    "
                                  >
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.text }}:
                                    </v-col>
                                    <v-col class="text-align:left" cols="6">
                                      {{ q_s_4.answer[0].valueString }}
                                    </v-col>
                                  </v-row>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="checkIfNotNull(q_s_3)">
                              <div v-if="q_s_3.answer.length !== 0">
                                <v-row
                                  class="mb-2"
                                  v-if="
                                    checkIfObjectIncludes(
                                      q_s_3.answer[0],
                                      'valueBoolean'
                                    )
                                  "
                                >
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.text }}:
                                  </v-col>
                                  <v-col class="text-align:left" cols="6">
                                    <p
                                      v-if="
                                        q_s_3.answer[0].valueBoolean === 'true'
                                      "
                                    >
                                      Ja
                                    </p>
                                    <p v-else>Nein</p>
                                  </v-col>
                                </v-row>
                                <v-row
                                  class="mb-2"
                                  v-else-if="
                                    checkIfObjectIncludes(
                                      q_s_3.answer[0],
                                      'valueDecimal'
                                    )
                                  "
                                >
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.text }}:
                                  </v-col>
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.answer[0].valueDecimal }}
                                  </v-col>
                                </v-row>
                                <v-row
                                  class="mb-2"
                                  v-else-if="
                                    checkIfObjectIncludes(
                                      q_s_3.answer[0],
                                      'valueCoding'
                                    )
                                  "
                                >
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.text }}:
                                  </v-col>
                                  <v-col class="text-align:left" cols="6">
                                    <div
                                      v-if="
                                        getAnswerColor(
                                          q_s_3.answer[0].valueCoding.display
                                        ) === 'red'
                                      "
                                    >
                                      <p style="background-color: red">
                                        {{
                                          q_s_3.answer[0].valueCoding.display
                                        }}
                                      </p>
                                    </div>
                                    <div
                                      v-else-if="
                                        getAnswerColor(
                                          q_s_3.answer[0].valueCoding.display
                                        ) === 'yellow'
                                      "
                                    >
                                      <p style="background-color: #ffd700">
                                        {{
                                          q_s_3.answer[0].valueCoding.display
                                        }}
                                      </p>
                                    </div>
                                    <div v-else>
                                      <p style="background-color: white">
                                        {{
                                          q_s_3.answer[0].valueCoding.display
                                        }}
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row
                                  class="mb-2"
                                  v-else-if="
                                    checkIfObjectIncludes(
                                      q_s_3.answer[0],
                                      'valueTime'
                                    )
                                  "
                                >
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.text }}:
                                  </v-col>
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.answer[0].valueTime }}
                                  </v-col>
                                </v-row>
                                <v-row
                                  class="mb-2"
                                  v-else-if="
                                    checkIfObjectIncludes(
                                      q_s_3.answer[0],
                                      'valueString'
                                    )
                                  "
                                >
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.text }}:
                                  </v-col>
                                  <v-col class="text-align:left" cols="6">
                                    {{ q_s_3.answer[0].valueString }}
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="checkIfNotNull(q_s_2)">
                          <div v-if="q_s_2.answer.length !== 0">
                            <v-row
                              class="mb-2"
                              v-if="
                                checkIfObjectIncludes(
                                  q_s_2.answer[0],
                                  'valueBoolean'
                                )
                              "
                            >
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.text }}:
                              </v-col>
                              <v-col class="text-align:left" cols="6">
                                <p
                                  v-if="q_s_2.answer[0].valueBoolean === 'true'"
                                >
                                  Ja
                                </p>
                                <p v-else>Nein</p>
                              </v-col>
                            </v-row>
                            <v-row
                              class="mb-2"
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_2.answer[0],
                                  'valueDecimal'
                                )
                              "
                            >
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.text }}:
                              </v-col>
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.answer[0].valueDecimal }}
                              </v-col>
                            </v-row>
                            <v-row
                              class="mb-2"
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_2.answer[0],
                                  'valueCoding'
                                )
                              "
                            >
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.text }}:
                              </v-col>
                              <v-col class="text-align:left" cols="6">
                                <div
                                  v-if="
                                    getAnswerColor(
                                      q_s_2.answer[0].valueCoding.display
                                    ) === 'red'
                                  "
                                >
                                  <p style="background-color: red">
                                    {{ q_s_2.answer[0].valueCoding.display }}
                                  </p>
                                </div>
                                <div
                                  v-else-if="
                                    getAnswerColor(
                                      q_s_2.answer[0].valueCoding.display
                                    ) === 'yellow'
                                  "
                                >
                                  <p style="background-color: #ffd700">
                                    {{ q_s_2.answer[0].valueCoding.display }}
                                  </p>
                                </div>
                                <div v-else>
                                  <p style="background-color: white">
                                    {{ q_s_2.answer[0].valueCoding.display }}
                                  </p>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row
                              class="mb-2"
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_2.answer[0],
                                  'valueTime'
                                )
                              "
                            >
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.text }}:
                              </v-col>
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.answer[0].valueTime }}
                              </v-col>
                            </v-row>
                            <v-row
                              class="mb-2"
                              v-else-if="
                                checkIfObjectIncludes(
                                  q_s_2.answer[0],
                                  'valueString'
                                )
                              "
                            >
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.text }}:
                              </v-col>
                              <v-col class="text-align:left" cols="6">
                                {{ q_s_2.answer[0].valueString }}
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-container>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-row>
        </v-col>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "QuestionnaireScoresView",

  data: () => ({
    questionnaire_scores: [],
    questionnaires: [],
    selectedQuestionnaire: "",
    dialog: false,
    questionnaire_refresh: false,
    questionnaire_to_refresh_data: "",
    questionnaire_to_refresh_index: "",
    patient_id: "",
  }),

  methods: {
    getQuestionnaireScores: function () {
      let patient_id = this.$route.params.id.split("&", 1);

      this.$axios
        .get("/get_questionnaire_scores/" + patient_id)
        .then(
          (response) => {
            this.questionnaire_scores = response.data;
            console.log(this.questionnaire_scores);
            this.sortQuestionnaires();
          },
          () => {}
        )
        .then(() => {
          console.log(this.treatmentDocumentationGrouped);
        });
    },

    createQFormPatient: function () {
      let patient_id = this.$route.params.id.split("&", 1);
      let payload = { patient_id: patient_id };

      this.$axios
        .post("/patient_qform/", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(
          (response) => {
            console.log(response.data);
          },
          () => {}
        )
        .then(() => {
          console.log(this.treatmentDocumentationGrouped);
        });
    },

    getQuestionnaires: function () {
      this.$axios
        .get("/get_qform_questionnaires/")
        .then(
          (response) => {
            this.questionnaires = response.data;
            console.log(response.data);
            console.log(JSON.stringify(this.questionnaires));
          },
          () => {}
        )
        .then(() => {
          console.log(this.treatmentDocumentationGrouped);
        });
    },

    assignQuestionnaire: function () {
      let patient_id = this.$route.params.id.split("&", 1);

      let fd = {
        patient_id: patient_id[0],
        questionnaire_id: Object.keys(this.questionnaires).find(
          (key) => this.questionnaires[key] === this.selectedQuestionnaire
        ),
      };
      this.$axios
        .post("/assign_questionnaire_qform/", fd)
        .then(
          (response) => {
            console.log(response.data);
          },
          () => {}
        )
        .then(() => {
          this.dialog = false;
        });
    },

    updateQuestionnaire: function () {
      // to do: post the updated questionaire
      console.log(this.questionnaire_to_refresh_data);
    },

    closeDialog: function () {
      this.dialog = false;
      this.getQuestionnaireScores();
    },

    switchedTo: function () {
      this.getQuestionnaireScores();
    },

    checkIfObjectIncludes: function (item, itemToCheck) {
      return item === null || item === undefined
        ? false
        : Object.prototype.hasOwnProperty.call(item, itemToCheck);
    },

    checkIfNotNull: function (el) {
      return el && el !== null && el !== undefined;
    },

    extractHoehnYahrScore: function (coding) {
      // check the first three characters for each possible answer

      // in case of confusion: answer I has S from word 'Streng' as the third character
      if (coding === "I S") {
        return 1;
      } else if (coding === "II ") {
        return 2;
      } else if (coding === "III") {
        return 3;
      } else if (coding === "IV ") {
        return 4;
        // in case of confusion: answer V has P from word 'Patient' as the third character
      } else if (coding === "V P") {
        return 5;
      }
      return;
    },

    getAnswerColor: function (text) {
      if (text === "4 schwer ausgeprägt") {
        return "red";
      } else if (text === "3 mäßig ausgeprägt") {
        return "yellow";
      } else {
        return "white";
      }
    },

    sortQuestionnaires: function () {
      for (let val in this.questionnaire_scores) {
        // sort the questionnaire dates in an anti-chronological order for each questionnaire-group
        // extracting either the 'poststempeldaum' or, if the questionnaire has no such attribute, use 'authored' as the sorting criteria instead
        let sortable = [];
        this.questionnaire_scores[val].forEach((element) => {
          // array inside array, but always access the second element of the nested array
          if (
            element[0].title === "Hoehn & Yahr" &&
            element[1].item.length === 1
          ) {
            // Hoehn-Yahr tends to have only one element which is no date, so it raise an error if it gets through the else if,
            // beauce it checks on element with index 2 --> it will also have most likely no 'Poststempeldatum', so take 'authored' as date
            sortable.push({ date: element[1].authored, id: element[1].id });
          } else if (
            element[0].title === "WHO-5 - Patient:innen (Kontrollgruppe)" &&
            (element[1].item[1].item[1].text ===
              "Ausgefüllt am.../ Poststempeldatum" ||
              element[1].item[1].text === "Ausgefüllt am.../")
          ) {
            sortable.push({
              date: element[1].item[1].item[1].answer[0].valueString,
              id: element[1].id,
            });
          } else {
            if (
              element[1].item[0].text ===
                "Ausgefüllt am.../ Poststempeldatum" ||
              element[1].item[0].text === "Ausgefüllt am.../"
            ) {
              sortable.push({
                date: element[1].item[0].answer[0].valueString,
                id: element[1].id,
              });
            } else if (
              element[1].item[1].text ===
                "Ausgefüllt am.../ Poststempeldatum" ||
              element[1].item[1].text === "Ausgefüllt am.../"
            ) {
              sortable.push({
                date: element[1].item[1].answer[0].valueString,
                id: element[1].id,
              });
            } else {
              sortable.push({ date: element[1].authored, id: element[1].id });
            }
          }
        });
        // sorting
        sortable = sortable.sort((a, b) => {
          // Ensure date exists and is a valid string
          if (
            !a.date ||
            !b.date ||
            typeof a.date !== "string" ||
            typeof b.date !== "string"
          ) {
            return 0; // If invalid, consider them equal
          }

          let partsA = a.date.match(/(\d+)/g) || [];
          let partsB = b.date.match(/(\d+)/g) || [];

          // Ensure there are enough parts before accessing indices
          if (partsA.length < 3 || partsB.length < 3) {
            return 0; // If date format is invalid, consider them equal
          }

          return (
            new Date(partsB[2], partsB[1] - 1, partsB[0]) -
            new Date(partsA[2], partsA[1] - 1, partsA[0])
          );
        });
        // sortable = sortable.reverse()
        // get the corresponding questionnaire from the stored id
        let temp = [];
        sortable.forEach((el) => {
          temp.push(
            this.questionnaire_scores[val].find(
              (element) => element[1].id === el.id
            )
          );
        });
        // refresh the questionnaire group for each loop
        this.questionnaire_scores[val] = temp;
      }
    },
  },

  computed: {},

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.getQuestionnaireScores();
  },
  expose: ["switchedTo"],
};
</script>
