<template>
  <v-form v-if="!display_only" ref="form" fast-fail @submit.prevent="addEval">
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="rating.left.score"
          :items="ratingScale[test_type]"
          item-title="display"
          item-value="score"
          label="UPDRS Score"
        ></v-select>
        <v-text-field
          :disabled="disabled"
          v-model="rating.left.comment"
          label="Kommentar"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="rating.right.score"
          :items="ratingScale[test_type]"
          item-title="display"
          item-value="score"
          label="UPDRS Score"
        ></v-select>
        <v-text-field
          :disabled="disabled"
          v-model="rating.right.comment"
          label="Kommentar"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
  </v-form>

  <v-table>
    <thead>
      <tr>
        <th class="text-left">Datum</th>
        <th class="text-left">Versorger</th>
        <th class="text-left">Bewertung linke Hand (Kommentar)</th>
        <th class="text-left">Bewertung rechte Hand (Kommentar)</th>
      </tr>
    </thead>
    <tbody v-for="(evaluations, key) in evalsGrouped" :key="key">
      <tr v-for="(evaluation, key) in evaluations" :key="key">
        <td class="text-left">
          {{ helpers.formatDateStringToGermanDateTime(evaluation.date) }}
        </td>
        <td class="text-left">{{ evaluation.user_id }}</td>
        <td class="text-left">
          {{ evaluation.eval["left"].score }} (
          {{ evaluation.eval["left"].comment }})
        </td>
        <td class="text-left">
          {{ evaluation.eval["right"].score }} (
          {{ evaluation.eval["right"].comment }})
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
export default {
  name: "DataRating",

  props: {
    fhir_id: String,
    test_type: String,
    display_only: Boolean,
    side: String,
    patient_id: String,
  },

  data: function () {
    return {
      evals: [],
      evalsGrouped: [],
      ratingScale: {
        tapping: [
          {
            score: 0,
            display: "UPDRS 0",
          },
          {
            score: 1,
            display: "UPDRS 1",
          },
          {
            score: 2,
            display: "UPDRS 2",
          },
          {
            score: 3,
            display: "UPDRS 3",
          },
          {
            score: 4,
            display: "UPDRS 4",
          },
        ],
        handmove: {
          0: "UPDRS 0",
          1: "UPDRS 1",
          2: "UPDRS 2",
          3: "UPDRS 3",
          4: "UPDRS 4",
        },
        rotation: {
          0: "UPDRS 0",
          1: "UPDRS 1",
          2: "UPDRS 2",
          3: "UPDRS 3",
          4: "UPDRS 4",
        },
      },
      rating: {
        left: {
          score: null,
          comment: "",
        },
        right: {
          score: null,
          comment: "",
        },
      },
    };
  },

  methods: {
    getDataRating: function () {
      return new Promise((resolve, reject) => {
        // Simulate an asynchronous operation (e.g., fetching data)
        this.$axios
          .get("/get_eval/" + this.fhir_id)
          .then(
            (response) => {
              this.evals = response.data;
              this.evals = Object.values(this.evals).sort(function (a, b) {
                return new Date(b.data) - new Date(a.date);
              });
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          )
          .then(() => {
            this.combineEvals();
            console.log(this.evals);
            resolve(this.evals);
          });
      });
    },
    combineEvals: function () {
      console.log(this.evals);
      const groupedData = this.evals.reduce((acc, item) => {
        // Extract the date portion without the time
        console.log(item);
        const fhir_id = item.fhir_id;

        // Check if the date exists in the accumulator
        if (!acc[fhir_id]) {
          acc[fhir_id] = [];
        }

        if (!acc[fhir_id]) {
          acc[fhir_id] = [];
        }

        // Push the item to the corresponding date
        acc[fhir_id] = acc[fhir_id].concat(item);

        return acc;
      }, {});

      console.log(groupedData);

      this.evalsGrouped = groupedData;
    },

    addEval: function () {
      let userInfo = this.helpers.getUserInfoFromToken(this.keycloak.token);

      let json_payload = {
        user_id: userInfo.user_name,
        patient_id: this.patient_id,
        fhir_id: this.fhir_id,
        date: new Date().toISOString(),
        eval: this.rating,
        side: this.side,
      };

      this.$axios
        .post("/eval/", json_payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(
          (response) => {
            if ("error" in response.data) {
              console.log(response.data);
              return;
            } else {
              console.log("Eval created");
              this.rating.score = null;
              this.rating.comment = "";
              this.getDataRating();
            }
          },
          () => {
            console.log("Eval not created");
          }
        );
    },
  },

  mounted() {
    this.getDataRating();
  },
};
</script>
