<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfProcedure"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Tag des Pflegekonsils"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRules"
        label="Dauer des Konsil (HH:MM)"
      ></v-text-field>

      <v-label class="text-left">Anwesende</v-label>
      <v-row>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Telenurse"
            value="telenurse"
          ></v-checkbox
        ></v-col>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Parkinson Nurse"
            value="parknurse"
          ></v-checkbox
        ></v-col>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Ambulanter Pflegedienst"
            value="ambnurse"
          ></v-checkbox
        ></v-col>
      </v-row>

      <v-file-input
        v-show="!disabled"
        v-model="careConsilFile"
        :rules="fileRules"
        accept=".pdf"
        label="Besprochene Problematik/ Anamnese"
      ></v-file-input>

      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Pflegeanamnese</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-textarea
        :readonly="disabled"
        v-model="patientInformation"
        label="Besprochene Patientenempfehlung (stichpunktartige Eingabe)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="relativesInformation"
        label="Besprochene Angehörigenempfehlung (stichpunktartige Eingabe)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="miscInformation"
        label="Sonstige besprochene Empfehlungen (stichpunktartige Eingabe)"
      ></v-textarea>

      <v-label class="text-left"
        >Wer bespricht Empfehlungen mit Patient*in?</v-label
      >
      <v-row>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="teleCall"
            label="Telenurse (telefonisch)"
          ></v-checkbox
        ></v-col>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="followUpVisit"
            label="Pflegedienst (2. Hausbesuch)"
          ></v-checkbox
        ></v-col>
      </v-row>

      <v-label class="text-left"
        >Entscheidung: Übergabe des Falls an Facharzt?</v-label
      >
      <v-radio-group :readonly="disabled" v-model="referral">
        <v-radio label="Ja" value="yes"></v-radio>
        <v-radio label="Nein" value="no"></v-radio>
      </v-radio-group>

      <v-textarea
        :readonly="disabled"
        v-model="doctorSuggestion"
        label="Besprochene Arztempfehlung (stichpunktartige Eingabe)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="careInformation"
        label="Besprochene pflegerische Maßnahmen (stichpunktartige Eingabe)"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "CareCouncil",

  components: {
    VuePdfEmbed,
  },

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Fallbesprechung im Pflegekonsil",
      type: "care_council",
      filePDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "careConsilFile"
          ).value
        : "Not provided",
      fileBlob: undefined,
      billableProcedures: this.document_data
        ? this.document_data.billable_procedures
        : [],
      careConsilFile: undefined,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRules: [
        (value) => {
          if (value) return true;

          return "Sie müssen eine Dauer angeben";
        },
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      attendees: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "attendees"
          ).value
        : [],
      patientInformation: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "patientInformation"
          ).value
        : "",
      relativesInformation: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "relativesInformation"
          ).value
        : "",
      doctorSuggestion: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "doctorSuggestion"
          ).value
        : "",
      careInformation: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "careInformation"
          ).value
        : "",
      miscInformation: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "miscInformation"
          ).value
        : "",
      followUpVisit: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "followUpVisit"
          ).value
        : false,
      teleCall: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "teleCall"
          ).value
        : false,
      referral: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "referral"
          ).value
        : "",
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      // First update billable procedures based on attendees
      if (this.attendees.includes("parknurse")) {
        this.billableProcedures.push("A11");
      }

      if (this.attendees.includes("ambnurse")) {
        this.billableProcedures.push("A12");
      }

      if (this.attendees.includes("telenurse")) {
        this.billableProcedures.push("A15");
      }

      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfProcedure,
        content: [
          { property: "duration", value: this.duration },
          { property: "attendees", value: this.attendees },
          { property: "patientInformation", value: this.patientInformation },
          {
            property: "relativesInformation",
            value: this.relativesInformation,
          },
          { property: "doctorSuggestion", value: this.doctorSuggestion },
          { property: "careInformation", value: this.careInformation },
          { property: "miscInformation", value: this.miscInformation },
          { property: "followUpVisit", value: this.followUpVisit },
          { property: "teleCall", value: this.teleCall },
          { property: "referral", value: this.referral },
          { property: "further", value: this.further },
        ],
        fileUploads: [
          {
            file: this.careConsilFile,
            property: "careConsilFile",
            prefix: "CareConsil",
          },
        ],
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },
    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    clearInput: function () {
      this.dateOfProcedure = "";
      this.dateMsg = true;
    },
  },

  watch: {
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },
  },

  mounted() {},
};
</script>
