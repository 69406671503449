<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled || init_exam"
            :rules="dateRule"
            v-model="dateOfVisit"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Eingangsuntersuchung"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer der Einweisung (HH:MM)"
      ></v-text-field>

      <v-label class="align-left">Studienzentrum</v-label>
      <v-radio-group :readonly="disabled || init_exam" v-model="studyCenter">
        <v-radio label="Marburg" value="MR"></v-radio>
        <v-radio label="Hamburg" value="HH"></v-radio>
      </v-radio-group>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "DeviceIntroduction",

  props: {
    document_data: Object,
    init_exam: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Instruktion in Wearable und App",
      type: "device_intro",
      dateOfVisit: this.document_data
        ? this.document_data.treatment_date
        : this.init_exam
        ? this.init_exam.treatment_date
        : "",
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      studyCenter: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "study_center"
          ).value
        : this.init_exam
        ? this.init_exam.content.find(
            (item) => item.property === "study_center"
          ).value
        : "",
      billableProcedures: ["A02"],
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfVisit,
        content: [
          { property: "duration", value: this.duration },
          { property: "study_center", value: this.studyCenter },
          { property: "further", value: this.further },
        ],
        fileUploads: [], // No file uploads in this component
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    clearInput: function () {
      this.dateOfVisit = "";
      this.dateMsg = true;
    },
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
