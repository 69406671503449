<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfProcedure"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Maßnahme"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-radio-group :readonly="disabled" v-model="feedback.status">
        <v-radio
          label="keine Rückmeldung erforderlich"
          v-bind:value="false"
        ></v-radio>
        <v-radio label="Rückmeldung erforderlich" v-bind:value="true"></v-radio>
      </v-radio-group>

      <v-textarea
        v-show="feedback.status == true"
        :readonly="disabled"
        v-model="feedback.content"
        label="Fragen und Anmerkungen an andere Versorger"
      ></v-textarea>

      <v-row>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Parkinson Nurse"
            value="parknurse"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Facharzt"
            value="doctor"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Tele Nurse"
            value="telenurse"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Pflegedienst"
            value="careservice"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Anderer externer Versorger"
            value="external"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-text-field
        :readonly="disabled"
        v-model="externalName"
        v-show="feedback.status && feedback.recipients.includes('external')"
        label="Externer Versorger"
      ></v-text-field>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "FeedbackCareTeam",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title:
        "Rückmeldung an das Versorgungsteam nach telefonischer Konsultation",
      type: "feedback_careteam",
      billableProcedures: ["57203A06"],
      feedback: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "feedback"
          ).value
        : {
            status: false,
            content: "",
            recipients: [],
            home_visit: { needed: false, reason: "" },
            specialist_visit: false,
          },
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      externalName: "",
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      // First fix the property if it doesn't exist
      if (!this.reasonsOfCall?.hospitalization) {
        this.reasonsOfCall = {
          ...this.reasonsOfCall,
          hospitalization: { discharge_letter_pdf: "" },
        };
      }

      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfProcedure,
        content: [{ property: "feedback", value: this.feedback }],
        fileUploads: [], // No file uploads in this component
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    clearInput: function () {
      this.dateOfProcedure = "";
      this.dateMsg = true;
    },
  },

  mounted() {
    console.log(this.dischargeLetterPDF);
  },
};
</script>
