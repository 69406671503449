<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfVisit"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Tag des Hausbesuchs"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="nurseId"
        label="Kürzel AWO Mitarbeiter"
      ></v-text-field>

      <v-label class="align-left">Grund des Hausbesuchs</v-label>
      <v-radio-group :readonly="disabled" v-model="reasonOfVisit">
        <v-radio label="Pflegeanamnese" value="care_anamnesis"></v-radio>
        <v-radio
          label="Nachbesprechung / Folgebesuch"
          value="re_visit"
        ></v-radio>
        <v-radio label="Sonstiges" value="misc"></v-radio>
      </v-radio-group>
      <v-text-field
        :readonly="disabled"
        v-if="reasonOfVisit == 'misc'"
        v-model="reasonOfVisitTxt"
        label="Grund des Hausbesuchs"
      ></v-text-field>

      <v-text-field
        :readonly="disabled"
        v-model="preparation_time"
        :rules="durationRules"
        label="Zeit für Vorbereitung (HH:MM)"
      ></v-text-field>
      <v-text-field
        :readonly="disabled"
        v-model="postprocessing_time"
        :rules="durationRules"
        label="Zeit für Nachbereitung (HH:MM)"
      ></v-text-field>

      <v-file-input
        v-show="!disabled"
        v-model="homeVisitFile"
        :rules="fileRules"
        accept=".pdf"
        label="Dokument hochladen"
      ></v-file-input>

      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Hausbesuch</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "HomeVisit",

  components: {
    VuePdfEmbed,
  },

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Hausbesuch durch Pflegedienst",
      type: "home_visit",
      filePDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "homeVisitFile"
          ).value
        : "Not provided",
      fileBlob: undefined,
      homeVisitFile: undefined,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      dateOfVisit: this.document_data ? this.document_data.treatment_date : "",
      nurseId: this.document_data
        ? this.document_data.content.find((item) => item.property === "nurseID")
            .value
        : "",
      reasonOfVisit: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasonOfVisit"
          ).value
        : "",
      reasonOfVisitTxt: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasonOfVisitTxt"
          ).value
        : "",
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      billableProcedures: ["A16"],
      disabled: this.disabled_field,
      preparation_time: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "preparation_time"
          ).value
        : "",
      postprocessing_time: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "postprocessing_time"
          ).value
        : "",
      durationRules: [
        (value) => {
          if (value) return true;

          return "Sie müssen eine Dauer angeben";
        },
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfVisit,
        content: [
          { property: "nurseID", value: this.nurseId },
          { property: "reasonOfVisit", value: this.reasonOfVisit },
          { property: "reasonOfVisitTxt", value: this.reasonOfVisitTxt },
          { property: "postprocessing_time", value: this.postprocessing_time },
          { property: "preparation_time", value: this.preparation_time },
          { property: "further", value: this.further },
        ],
        fileUploads: [
          {
            file: this.homeVisitFile,
            property: "homeVisitFile",
            prefix: "HomeVisit",
          },
        ],
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },
    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    clearInput: function () {
      this.dateOfVisit = "";
      this.dateMsg = true;
    },
  },

  watch: {
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
