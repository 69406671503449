<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfCall"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Fallbesprechung"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer der Fallbesprechung"
      ></v-text-field>

      <v-textarea
        :readonly="disabled"
        v-model="discussion.topics"
        label="Besprochene Themen (stichpunktartig)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.measures"
        label="Besprochene Maßnahmen (stichpunktartig)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.problems"
        label="Besprochene Problematiken"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.interventions"
        label="Besprochene Interventionen"
      ></v-textarea>

      <v-select
        :readonly="disabled"
        v-model="selected.values"
        :items="selects"
        label="Wer bespricht Maßnahmen mit Patient?"
        multiple
        @update:model-value="checkSelections()"
      ></v-select>

      <v-textarea
        v-show="if_1"
        :readonly="disabled"
        v-model="further.documentation.content.act"
        label="anderes Vorgehen (bitte erläutern)"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content.further"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "CaseDiscussionDoctor",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Fallbesprechung Telenurse und Fachärzt*in",
      type: "case_discussion_doctor",
      dateOfCall: this.document_data ? this.document_data.treatment_date : "",
      billableProcedures: this.document_data
        ? this.document_data.billable_procedures
        : ["A08", "A09"],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: { further: "", act: "" } },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      discussion: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "discussion"
          ).value
        : {
            problems: "",
            interventions: "",
            topics: "",
            measures: "",
          },
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      selects: [
        "Telenurse (telefonisch)",
        "Facharzt (telefonisch)",
        "anderes Vorgehen (bitte erläutern)",
      ],
      selected: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "selected"
          ).value
        : { values: [] },
      if_1: false,
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfCall,
        content: [
          { property: "duration", value: this.duration },
          { property: "discussion", value: this.discussion },
          { property: "further", value: this.further },
          { property: "selected", value: this.selected },
        ],
        fileUploads: [], // No file uploads in this component
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    checkSelections: function () {
      if (this.selected.values.includes("anderes Vorgehen (bitte erläutern)")) {
        this.if_1 = true;
      } else {
        this.if_1 = false;
      }
    },

    clearInput: function () {
      this.dateOfCall = "";
      this.dateMsg = true;
    },
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
