<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfCall"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Tag der Fallbesprechung"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer der Fallbesprechung"
      ></v-text-field>
      <v-textarea
        :readonly="disabled"
        v-model="reasonOfCouncil"
        label="Grund für Konsil"
      ></v-textarea>

      <v-label class="text-left">Anwesende</v-label>
      <v-row>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Facharzt"
            value="doctor"
          ></v-checkbox
        ></v-col>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Telenurse"
            value="telenurse"
          ></v-checkbox
        ></v-col>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Parkinson Nurse"
            value="parknurse"
          ></v-checkbox
        ></v-col>
        <v-col
          ><v-checkbox
            :readonly="disabled"
            v-model="attendees"
            label="Ambulanter Pflegedienst"
            value="ambnurse"
          ></v-checkbox
        ></v-col>
      </v-row>

      <v-textarea
        :readonly="disabled"
        v-model="discussion.problems"
        label="Besprochene Problematiken"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.interventions"
        label="Besprochene Interventionen"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "CaseDiscussion",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Fallbesprechung im Konsil",
      type: "case_discussion",
      dateOfCall: this.document_data ? this.document_data.treatment_date : "",
      reasonOfCouncil: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasonOfCouncil"
          ).value
        : "",
      billableProcedures: this.document_data
        ? this.document_data.billable_procedures
        : [],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      attendees: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "attendees"
          ).value
        : [],
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      discussion: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "discussion"
          ).value
        : {
            problems: "",
            interventions: "",
          },
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      // First update billable procedures based on attendees
      if (this.attendees.includes("telenurse")) {
        this.billableProcedures.push("A15");
      }

      if (this.attendees.includes("ambnurse")) {
        this.billableProcedures.push("A14");
      }

      if (this.attendees.includes("doctor")) {
        this.billableProcedures.push("A13");
      }

      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfCall,
        content: [
          { property: "reasonOfCouncil", value: this.reasonOfCouncil },
          { property: "attendees", value: this.attendees },
          { property: "duration", value: this.duration },
          { property: "discussion", value: this.discussion },
          { property: "further", value: this.further },
        ],
        fileUploads: [], // No file uploads in this component
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    clearInput: function () {
      this.dateOfCall = "";
      this.dateMsg = true;
    },
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
