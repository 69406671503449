/**
 * File Upload Service - Handles file uploads and form submissions in a consistent way
 */

import { jwtDecode } from "jwt-decode";

/**
 * Uploads a file to the server
 * @param {Object} axios - Axios instance from component
 * @param {File} file - The file to upload
 * @param {String} filePrefix - Prefix for the file name (e.g., "PatientCall")
 * @param {String} patientId - ID of the patient
 * @returns {Promise<String>} - Path to the uploaded file or "Not provided" if upload fails
 */
export const uploadFile = async (axios, file, filePrefix, patientId) => {
  if (!file) {
    console.log("No file provided for upload");
    return "Not provided";
  }
  
  try {
    // Extract the actual file object if it's in an array
    const fileToUpload = Array.isArray(file) && file.length > 0 ? file[0] : file;
    
    if (!fileToUpload) {
      console.log("No valid file found after extraction");
      return "Not provided";
    }
    
    console.log(`Preparing to upload ${filePrefix} file:`, fileToUpload.name);
    
    const blob = fileToUpload;
    const newFile = new File(
      [blob],
      `${filePrefix}_${patientId}_${Math.floor(new Date().getTime() / 1000)}.pdf`,
      { type: blob.type }
    );
    
    const formData = new FormData();
    formData.append("file", newFile);

    console.log(`Uploading ${filePrefix} file:`, newFile.name);
    
    const response = await axios.post("/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if ("error" in response.data) {
      console.log(`Upload error for ${filePrefix}:`, response.data);
      return "Not provided";
    }
    
    console.log(`File Upload successful for ${filePrefix}`);
    return response.data.success.path;
  } catch (error) {
    console.log(`Error uploading ${filePrefix} file:`, error);
    return "Not provided";
  }
};

/**
 * Submits the form to the treatment documentation endpoint
 * @param {Object} axios - Axios instance from component
 * @param {Object} payload - The JSON payload to submit
 * @returns {Promise<Object>} - The response from the server
 */
export const submitDocumentation = async (axios, payload) => {
  try {
    console.log("Submitting documentation with payload:", JSON.stringify(payload, null, 2));
    
    const response = await axios.post("/treatment_documentation/", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    console.log("Documentation response:", response);
    return response;
  } catch (error) {
    console.log("Error submitting documentation:", error);
    throw error;
  }
};

/**
 * Creates a complete documentation submission, including file uploads
 * @param {Object} options - Configuration options
 * @param {Object} options.form - Form reference (this.$refs.form)
 * @param {Object} options.axios - Axios instance (this.$axios)
 * @param {Function} options.keycloak - Keycloak instance (this.keycloak)
 * @param {String} options.patientIdParam - Path parameter containing patient ID (this.$route.params.id)
 * @param {String} options.title - Document title (this.title)
 * @param {String} options.type - Document type (this.type)
 * @param {Array} options.billableProcedures - Billable procedures (this.billableProcedures)
 * @param {String} options.treatmentDate - Date of treatment (this.dateOfVisit or this.dateOfProcedure)
 * @param {Array} options.content - Content array without files
 * @param {Array} options.fileUploads - Array of file upload configurations
 * @param {File} options.fileUploads[].file - File to upload
 * @param {String} options.fileUploads[].property - Property name for the file path in content
 * @param {String} options.fileUploads[].prefix - Prefix for the file name
 * @param {Function} options.onBeforeSubmit - Callback to modify content array before payload creation
 * @param {Function} options.onSuccess - Callback on successful submission
 * @param {Function} options.onSubmitStart - Callback when submission starts
 * @returns {Promise<void>}
 */
export const handleDocumentationSubmission = async (options) => {
  const { 
    form, 
    axios, 
    keycloak, 
    patientIdParam,
    title,
    type,
    billableProcedures,
    treatmentDate,
    content,
    fileUploads = [],
    onBeforeSubmit,
    onSuccess,
    onSubmitStart = () => {}
  } = options;

  try {
    const status = await form.validate();
    
    if (!status.valid) {
      console.log("Form validation failed");
      return;
    }
    
    // Call submit start callback (e.g., to disable form)
    onSubmitStart();
    
    // Get user information
    const token = keycloak.token;
    const decoded_token = jwtDecode(token);
    const user_name = decoded_token.preferred_username;
    
    // Extract patient ID from route param (assuming format like "123&something")
    const patient_id = patientIdParam.split("&", 1)[0];
    
    console.log(`Processing submission for patient ${patient_id}, user ${user_name}`);
    console.log(`Found ${fileUploads.length} files to upload`);
    
    // Clone the content array
    const contentWithFiles = [...content];
    
    // Process each file upload one by one (sequentially) for better reliability
    for (const { file, property, prefix } of fileUploads) {
      console.log(`Processing file upload for property: ${property}`);
      
      if (!file || (Array.isArray(file) && file.length === 0)) {
        console.log(`No file provided for ${property}`);
        contentWithFiles.push({ property, value: "Not provided" });
        continue;
      }
      
      // Upload the file
      const filePath = await uploadFile(axios, file, prefix, patient_id);
      console.log(`Uploaded file for ${property}, path: ${filePath}`);
      contentWithFiles.push({ property, value: filePath });
    }
    
    // Log the state after file uploads
    console.log("Content with files after uploads:", JSON.stringify(contentWithFiles, null, 2));
    
    // Apply onBeforeSubmit callback if provided
    let finalContent = contentWithFiles;
    if (onBeforeSubmit && typeof onBeforeSubmit === 'function') {
      const processedContent = onBeforeSubmit(contentWithFiles);
      if (processedContent) {
        finalContent = processedContent;
        console.log("Content after processing:", JSON.stringify(finalContent, null, 2));
      }
    }
    
    // Create payload
    const payload = {
      user_id: user_name,
      patient_id,
      treatment_date: treatmentDate,
      documentation_date: new Date().toISOString(),
      treamtent_type: type,
      billable_procedures: billableProcedures,
      title,
      content: finalContent,
    };
    
    console.log("Submitting final payload");
    
    // Submit the form
    const response = await submitDocumentation(axios, payload);
    
    if ("success" in response.data) {
      console.log("Documentation successfully created");
      if (onSuccess) onSuccess(response);
    } else {
      console.log("Documentation creation response:", response.data);
    }
  } catch (error) {
    console.error("Error in documentation submission:", error);
  }
};