<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfProcedure"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Maßnahme"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRules"
        label="Dauer des Telefonats (HH:MM)"
      ></v-text-field>

      <!-- Besprechung einer Verschlechterung/ Veränderung -->
      <v-label class="text-left">Grund für das Telefonat</v-label>
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.status"
        label="Besprechung einer Verschlechterung/ Veränderung"
      ></v-checkbox>
      <v-select
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.selected"
        :items="reasonsOfCall.worsening.selects"
        label="Was hat sich verschlechtert/verändert?"
        multiple
        @update:model-value="checkSelections()"
      ></v-select>
      <v-textarea
        v-show="
          reasonsOfCall.worsening.status && reasonsOfCall.worsening.changeBool
        "
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.change"
        label="andere Verschlechterungen/Veränderungen"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.selected_measures"
        :items="reasonsOfCall.worsening.selects_measures"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <!-- Kontaktwunsch der/ des Patient*in -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.status"
        label="Kontaktwunsch der/ des Patient*in"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.patient_wish.status"
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.patient_wish.status"
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.selected"
        :items="reasonsOfCall.patient_wish.selects"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.patient_wish.status"
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <!-- Besprechung einer unregelmäßigen Nutzung des Monitorings -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.status"
        label="Besprechung einer unregelmäßigen Nutzung des Monitorings"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.non_use.status"
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.non_use.status"
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.selected"
        :items="reasonsOfCall.non_use.selects"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.non_use.status"
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <!-- Nachbesprechung des Pflegekonsils -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.care_council.status"
        label="Nachbesprechung des Pflegekonsils"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.care_council.status"
        :readonly="disabled"
        v-model="reasonsOfCall.care_council.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.care_council.status"
        :readonly="disabled"
        v-model="reasonsOfCall.care_council.suggestions"
        label="Ausgegebene Empfehlung"
      ></v-textarea>

      <!-- Nachbesprechung zu Empfehlung/en -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.status"
        label="Nachbesprechung zu Empfehlung/en"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.suggestions.status"
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.full_compiliant"
        label="Umgesetzte Empfehlungen"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.suggestions.status"
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.moderate_compliant"
        label="Teilweise umgesetzte Empfehlungen"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.suggestions.status"
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.non_compliant"
        label="Nicht umgesetzte Empfehlungen"
      ></v-textarea>

      <!-- Nachbesprechung des Krankenhausaufenthalt -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.hospitalization.status"
        label="Nachbesprechung des Krankenhausaufenthalt"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.hospitalization.status"
        :readonly="disabled"
        v-model="reasonsOfCall.hospitalization.reason_care"
        label="Datum, Behandlung und Grund für Hospitalisierung"
      ></v-textarea>
      <v-file-input
        v-show="!disabled && reasonsOfCall.hospitalization.status"
        v-model="dischargeLetter"
        :rules="fileRules"
        accept=".pdf"
        label="Entlass-/Arztbrief"
        @change="dischargeFileBlob = null"
      ></v-file-input>

      <v-expansion-panels v-if="dischargeLetterPDF">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Entlass-/Arztbrief</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed
              v-show="dischargeLetterPDF"
              :source="dischargeFileBlob"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- 2-monatliches Follow-up Telefonat -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.status"
        label="2-monatliches Follow-up Telefonat"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.follow_up.status"
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.follow_up.status"
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.selected"
        :items="reasonsOfCall.follow_up.selects"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.follow_up.status"
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <v-file-input
        v-show="!disabled"
        v-model="patientCallFile"
        :rules="patientCallFileRules"
        accept=".pdf"
        label="Dokumentation hochladen"
        @change="fileBlob = null"
      ></v-file-input>

      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Dokumentationsdatei</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="fileBlob" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-textarea
        :readonly="disabled"
        v-model="reasonsOfCall.misc"
        label="Sonstige Inhalte"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import { jwtDecode } from "jwt-decode";
import { handleDocumentationSubmission } from "@/services/FileUploadService";

export default {
  name: "PatientCall",

  components: {
    VuePdfEmbed,
  },

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Kontakt Telenurse - Patient",
      type: "patient_call",
      filePDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "patientCallFile"
          )?.value || "Not provided"
        : "Not provided",
      fileBlob: null,
      patientCallFile: null,
      dischargeLetterPDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasons_of_call"
          )?.value?.hospitalization?.discharge_letter_pdf || null
        : null,
      dischargeFileBlob: null,
      billableProcedures: ["A06a"],
      reasonsOfCall: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasons_of_call"
          ).value
        : {
            worsening: {
              status: false,
              selects: [
                "Sensordaten",
                "Symptome im Tagebuch",
                "Wohlbefinden",
                "anderes",
              ],
              selected: [],
              selects_measures: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected_measures: [],
              change: "",
              call_content: "",
              suggestions_patient: "",
              changeBool: false,
            },
            patient_wish: {
              status: false,
              selects: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected: [],
              call_content: "",
              suggestions_patient: "",
            },
            non_use: {
              status: false,
              selects: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected: [],
              call_content: "",
              suggestions_patient: "",
            },
            care_council: { status: false, call_content: "", suggestions: "" },
            suggestions: {
              status: false,
              suggestions: {
                full_compiliant: "",
                moderate_compliant: "",
                non_compliant: "",
              },
            },
            hospitalization: {
              status: false,
              reason_care: "",
              discharge_letter_pdf: "",
            },
            follow_up: {
              status: false,
              selects: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected: [],
              call_content: "",
              suggestions_patient: "",
            },
            misc: "",
          },
      dischargeLetter: null,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      patientCallFileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRules: [
        (value) => {
          if (value) return true;

          return "Sie müssen eine Dauer angeben";
        },
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      disabled: this.disabled_field,
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      const reasonsOfCallCopy = JSON.parse(JSON.stringify(this.reasonsOfCall));
      const fileUploads = [];

      // Add discharge letter if it exists and hospitalization is enabled
      if (
        this.dischargeLetter &&
        this.reasonsOfCall.hospitalization &&
        this.reasonsOfCall.hospitalization.status
      ) {
        fileUploads.push({
          file: this.dischargeLetter,
          property: "discharge_letter",
          prefix: "DischargeLetter",
        });
      }

      // Add patient call file if it exists
      if (this.patientCallFile) {
        fileUploads.push({
          file: this.patientCallFile,
          property: "patientCallFile",
          prefix: "PatientCall",
        });
      } else {
        // If no patient call file is provided, add a "Not provided" value
        reasonsOfCallCopy.patientCallFile = "Not provided";
      }

      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfProcedure,
        content: [
          { property: "reasons_of_call", value: reasonsOfCallCopy },
          { property: "duration", value: this.duration },
          { property: "further", value: this.further },
        ],
        fileUploads: fileUploads,
        onBeforeSubmit: (contentArray) => {
          // Find the discharge letter file path if it exists
          const dischargeLetter = contentArray.find(
            (item) => item.property === "discharge_letter"
          );

          // Find the reasonsOfCall item
          const reasonsOfCallItem = contentArray.find(
            (item) => item.property === "reasons_of_call"
          );

          if (dischargeLetter && reasonsOfCallItem?.value?.hospitalization) {
            // Update the discharge letter path in reasonsOfCall
            reasonsOfCallItem.value.hospitalization.discharge_letter_pdf =
              dischargeLetter.value;

            // Remove the temporary discharge letter property
            return contentArray.filter(
              (item) => item.property !== "discharge_letter"
            );
          }

          return contentArray;
        },
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },

    fetchDischargePDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.dischargeLetterPDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.dischargeFileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    checkSelections: function () {
      if (this.reasonsOfCall.worsening.selected.includes("anderes")) {
        this.reasonsOfCall.worsening.changeBool = true;
      } else {
        this.reasonsOfCall.worsening.changeBool = false;
      }
    },

    clearInput: function () {
      this.dateOfProcedure = "";
      this.dateMsg = true;
    },
  },

  watch: {
    dischargeLetterPDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchDischargePDFFile();
        }
        console.log(this.dischargeFileBlob);
      },
    },
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },
  },

  mounted() {},
};
</script>
