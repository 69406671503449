<template>
  <v-container>
    <v-form
      ref="form"
      :disabled="submitted"
      fast-fail
      @submit.prevent="addDocumentation"
    >
      <div v-if="dateMsg">
        <p class="text-red">
          Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums
        </p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfVisit"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Eingangsuntersuchung"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer der Eingangsuntersuchung (HH:MM)"
      ></v-text-field>

      <v-file-input
        v-show="!disabled"
        v-model="initialExamFile"
        :rules="fileRules"
        accept=".pdf"
        label="Eingangsuntersuchung"
      ></v-file-input>

      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Eingangsuntersuchung</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-label class="align-left">Studienzentrum</v-label>
      <v-radio-group :readonly="disabled" v-model="study_center">
        <v-radio label="Marburg" value="MR"></v-radio>
        <v-radio label="Hamburg" value="HH"></v-radio>
      </v-radio-group>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-select
        :readonly="disabled"
        v-model="selected.values"
        :items="selects"
        label="Optional auszuwählen"
        multiple
      ></v-select>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { handleDocumentationSubmission } from "@/services/FileUploadService";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "InitExam",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  components: {
    VuePdfEmbed,
  },

  data: function () {
    return {
      title: "Eingangsuntersuchung in der Praxis/Klinik",
      type: "init_exam",
      dateOfVisit: this.document_data ? this.document_data.treatment_date : "",
      filePDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "initialExamFile"
          ).value
        : "Not provided",
      fileBlob: undefined,
      initialExamFile: undefined,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration"
          ).value
        : "",
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      study_center: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "study_center"
          ).value
        : "",
      billableProcedures: ["A01"],
      disabled: this.disabled_field,
      selects: ["Pflegeassessment angezeigt", "Arztkonsultation angezeigt"],
      selected: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "selected"
          ).value
        : { values: [] },
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      handleDocumentationSubmission({
        form: this.$refs.form,
        axios: this.$axios,
        keycloak: this.keycloak,
        patientIdParam: this.$route.params.id,
        title: this.title,
        type: this.type,
        billableProcedures: this.billableProcedures,
        treatmentDate: this.dateOfVisit,
        content: [
          { property: "duration", value: this.duration },
          { property: "study_center", value: this.study_center },
          { property: "further", value: this.further },
          { property: "selected", value: this.selected },
        ],
        fileUploads: [
          {
            file: this.initialExamFile,
            property: "initialExamFile",
            prefix: "InitialExam",
          },
        ],
        onSubmitStart: () => {
          this.submitted = true;
        },
        onSuccess: () => {
          this.$emit("close-dialog");
        },
      });
    },
    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    clearInput: function () {
      this.dateOfVisit = "";
      this.dateMsg = true;
    },
  },

  watch: {
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
